<template>
<div>
  <div id="main" class="site-main">

<div id="main-content" class="main-content">

<mainslider/>
<div id="page-title" class="page-title-block page-title-style-1 has-background-image" style="background-image: url(/assets/backgrounds/title/06.jpg);"><div class="container"><div class="page-title-icon"><div class="sc-icon sc-icon-size-medium angle-90deg sc-icon-shape-circle" style="border: 2px solid #ffffff;"><span class="sc-icon-half-1" style="color: #ffffff;"><span class="back-angle">&#xe7c0;</span></span><span class="sc-icon-half-2" style="color: #ffffff;"><span class="back-angle">&#xe7c0;</span></span></div></div><div class="page-title-title"><h1 style="">  Партнёры</h1></div></div></div>




<div class="block-content">
	<div class="container">
		<div class="panel row">

			<div class="panel-center col-xs-12">
				<article id="post-11278" class="post-11278 page type-page status-publish hentry">


					<div class="entry-content post-content">
												<div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper">
	<div class="wpb_text_column wpb_content_element " >
		<div class="wpb_wrapper">
			<h3>Федеральные органы исполнительной власти</h3>
<p>Реализация социальных проектов одна из важнейших задач государства. С развитием программ социального туризма решаются вопросы социального обеспечения, оздоровления, культуры, образования, популяризации народных художественных промыслов, агротуризма, природных заповедников. В период низкого сезона  социальный туризм обеспечивает заполняемость и работу инфраструктуры гостеприимства регионов. </p>
<p>Министерства и ведомства Российской Федерации при координации проектов по социальному туризму получают возможность встроить их в существующие и планируемые программы по развитию своей отрасли.</p>
<p>Сотрудничество между ведомствами,  позволяет снизить стоимость социальных программ и повысить их качество, осуществляя контроль качества и соответствия оказываемых услуг по программам проекта социального туризма.</p>

		</div>
	</div>
</div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="clearboth"></div><div class="sc-divider sc-divider-style-6" style=""></div></div></div></div></div><br />
<div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper">
	<div class="wpb_text_column wpb_content_element " >
		<div class="wpb_wrapper">
			<h3>Региональные органы исполнительной власти</h3>
<p>В последние годы многие регионы России занимаются развитием своего рекреационного потенциала с целью привлечения новых туристов. Некоторые регионы, при разработке плана по развитию туризма выделяют социальный туризм как отдельное направление, создавая для этого соответствующие условия, развивая инфраструктуру для детей, лиц пожилого возраста, многодетных семей </p>
<p>Для координации работы при выработке в регионах РФ единых требований к маршрутам социального туризма и согласования программ между регионами была создана «Ассоциация развития социального туризма» (АРСТ).</p>
<p>С целью реализации указанных задач после подписания между АРСТ и субъектами РФ соглашений о намерениях совместно развивать сотрудничество в рамках развития социального туризма, формируются рабочие группы для подготовки программ и маршрутов, адаптированных под социальные группы населения, как культурно-познавательные, образовательные, так и с целью оздоровления и лечения.</p>

		</div>
	</div>
</div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="clearboth"></div><div class="sc-divider sc-divider-style-6" style=""></div></div></div></div></div></p>
					</div><!-- .entry-content -->




				</article><!-- #post-## -->

			</div>


		</div>


	</div>
</div>



</div>


</div>
</div>

</template>

<script>
import mainslider from "@/components/mainslider.vue"
import {rollup} from "@/lib/utils.js"
export default {
name : "Parthners",
components : {mainslider},
mounted(){
rollup()

}

}
</script>
